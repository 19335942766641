import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import { ItemMessagesListMessageIconsMessage } from './ItemMessagesListMessageIcons.types';

import {
  ItemMessagesListMessageLikesType,
  ItemMessagesListMessageLikesList
} from '../ItemMessagesListMessageLikesList';

import { Icon } from '../../../../../../../helpers/Icon';

interface ItemMessagesListMessageIconsProps {
  className?: ClassName;
  message: ItemMessagesListMessageIconsMessage &
    ItemMessagesListMessageLikesType;
  messagesCacheKey: FetchMessagesCacheKey;
  reverse?: boolean;
}

function ItemMessagesListMessageIcons({
  className,
  message,
  messagesCacheKey,
  reverse = false
}: ItemMessagesListMessageIconsProps) {
  if (
    !message.favorite &&
    !message.flagged &&
    !message.pinnedAt &&
    isEmpty(message.messageLikes)
  ) {
    return null;
  }

  return (
    <div
      className={cl(
        className || 'relative -mt-1.5 flex pb-0.5 space-x-1 px-1.5',
        {
          'flex-row-reverse space-x-reverse': reverse
        }
      )}
    >
      {message.favorite ? (
        <div className="bg-yellow-500 flex h-6 w-6 p-1 items-center ring ring-white dark:ring-gray-900 rounded-full text-white">
          <Icon icon={IconsEnum.FILLED_STAR} />
        </div>
      ) : null}
      {message.flagged ? (
        <div className="bg-purple-500 flex h-6 w-6 p-1 items-center ring ring-white dark:ring-gray-900 rounded-full text-white">
          <Icon icon={IconsEnum.FLAG} />
        </div>
      ) : null}
      {message.pinnedAt ? (
        <div className="bg-gray-500 flex h-6 w-6 p-1 items-center ring ring-white dark:ring-gray-900 rounded-full text-white">
          <Icon icon={IconsEnum.PIN} />
        </div>
      ) : null}
      <ItemMessagesListMessageLikesList
        message={message}
        messagesCacheKey={messagesCacheKey}
      />
    </div>
  );
}

export default ItemMessagesListMessageIcons;
