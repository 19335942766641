import { useEffect, useMemo } from 'react';

import {
  OperationFields,
  OperationStates,
  OperationTypes,
  OperationTeamNanoID,
  FetchOperationsCacheKey
} from '../../../../../operationsTypes';
import {
  CreateOperationFormData,
  CreateOperationFormFields
} from '../../CreateOperationForm.types';
import { ProjectID } from '../../../../../../projects/projectsTypes';
import { TaskID } from '../../../../../../tasks/tasksTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { CREATE_OPERATION_QUERY } from '../../../../../queries/createOperation.query';

import { useCreateOperation } from '../../../../../hooks/useCreateOperation';

import { getDueDate } from '../../../../../../../utils/getDueDate';
import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';

import { createOperationFormSchema } from './useCreateOperationForm.schema';

const emptyDefaultValues: CreateOperationFormData = {
  clientId: null,
  createCompanyRecord: false,
  subject: '',
  body: '',
  date: getDueDate(new Date()),
  completeDatetime: null,
  operationType: OperationTypes.TASK,
  state: OperationStates.COMPLETED,
  fileAttachmentIds: [],
  mentionedUserIds: []
};

interface useCreateOperationFormOptions {
  cacheKeys?: FetchOperationsCacheKey[];
  onSuccess?: () => void;
  afterSubmit?: () => void;
  taskId?: TaskID;
  projectId?: ProjectID;
  teamNanoId?: OperationTeamNanoID;
  defaultValues?: Partial<CreateOperationFormData>;
}

function useCreateOperationForm({
  cacheKeys,
  onSuccess,
  afterSubmit,
  taskId,
  projectId,
  teamNanoId,
  defaultValues
}: useCreateOperationFormOptions) {
  const defaultProjectValues = useMemo<CreateOperationFormData>(
    () => ({
      ...emptyDefaultValues,
      ...defaultValues
    }),
    [defaultValues]
  );

  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue,
    watch
  } = useReactHookForm<CreateOperationFormData>({
    defaultValues: defaultProjectValues,
    isModalForm: true,
    schema: createOperationFormSchema
  });

  const {
    createOperation,
    createOperationErrorMessage,
    createOperationReset,
    createOperationLoading
  } = useCreateOperation({
    query: CREATE_OPERATION_QUERY,
    cacheKeys
  });

  const watchCreateCompanyRecord = watch(
    CreateOperationFormFields.CREATE_COMPANY_RECORD
  );

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (
        name === CreateOperationFormFields.CREATE_COMPANY_RECORD &&
        data[CreateOperationFormFields.CLIENT_ID]
      ) {
        setValue(CreateOperationFormFields.CLIENT_ID, null);
      }

      if (
        name === CreateOperationFormFields.CREATE_COMPANY_RECORD &&
        !data[CreateOperationFormFields.CLIENT_ID]
      ) {
        setValue(
          CreateOperationFormFields.CLIENT_ID,
          defaultProjectValues.clientId
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [defaultProjectValues.clientId, setValue, watch]);

  return {
    validationErrors: {
      subjectValidationError: errors?.subject?.message,
      bodyValidationError: errors?.body?.message,
      dateValidationError: errors?.date?.message,
      completeDatetimeValidationError: errors?.completeDatetime?.message,
      clientIdValidationError: errors?.clientId?.message,
      createCompanyRecordError: errors?.createCompanyRecord?.message
    },
    control,
    resetCreateOperationForm: resetForm,
    createOperationReset,
    createOperationErrorMessage,
    createOperationLoading,
    watchCreateCompanyRecord,
    handleCreateOperation: handleSubmitReactHookForm({
      onSubmit: async (data) =>
        createOperation({
          ...data,
          teamNanoId: data.createCompanyRecord ? teamNanoId : null,
          clientId: data.createCompanyRecord ? null : data.clientId,
          completeDatetime: data.completeDatetime
            ? dateFnsConvert.toDateTimeWithTimezone(data.completeDatetime)
            : null,
          date: dateFnsConvert.toDateTimeWithTimezone(data.date),
          taskId,
          projectId
        }).then(() => {
          onSuccess?.();
          afterSubmit?.();
        }),
      dirtyFieldsOnly: false
    }),

    registerFields: {
      registerBody: register(OperationFields.BODY),
      registerDate: register(OperationFields.DATE),
      registerCompleteDatetime: register(OperationFields.COMPLETE_DATETIME),
      registerSubject: register(OperationFields.SUBJECT),
      registerCreateCompanyRecord: register(
        CreateOperationFormFields.CREATE_COMPANY_RECORD
      )
    }
  };
}

export default useCreateOperationForm;
