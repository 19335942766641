import { useCallback } from 'react';
import cl from 'classnames';

import {
  MessageLikeEmojiText,
  MessageLikeCount,
  MessageLikeType,
  FetchMessagesCacheKey,
  MessageLikeUsers,
  MessageUUID
} from '../../../../../../../messagesTypes';

import {
  TOGGLE_MESSAGE_LIKE_QUERY,
  ToggleMessageLikeRecordType
} from '../../../../../../../queries/toggleMessageLike.query';

import {
  ToggleMessageLikeResponse,
  useToggleMessageLike
} from '../../../../../../../hooks/useToggleMessageLike';
import { PureTwemojiCountButtonHelper } from '../../../../../../../../../helpers/buttons/PureTwemojiCountButtonHelper';

interface ItemMessagesListMessageLikesListItemProps {
  emojiText: MessageLikeEmojiText;
  messageUuid: MessageUUID;
  messageLikeType: MessageLikeType;
  emojiCount: MessageLikeCount;
  isCurrentUserMessageLike: boolean;
  messagesCacheKey: FetchMessagesCacheKey;
  tooltipUsers: MessageLikeUsers;
}

function ItemMessagesListMessageLikesListItem({
  emojiText,
  messageUuid,
  messageLikeType,
  messagesCacheKey,
  emojiCount,
  isCurrentUserMessageLike,
  tooltipUsers
}: ItemMessagesListMessageLikesListItemProps) {
  const { toggleMessageLike, toggleMessageLikeLoading } =
    useToggleMessageLike<ToggleMessageLikeRecordType>({
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_LIKE_QUERY
    });

  const handleToggleMessageLike = useCallback<
    () => Promise<ToggleMessageLikeResponse<ToggleMessageLikeRecordType>>
  >(
    () =>
      toggleMessageLike({
        uuid: messageUuid,
        messageLikeType: messageLikeType
      }),
    [messageUuid, messageLikeType, toggleMessageLike]
  );

  return (
    <PureTwemojiCountButtonHelper
      className={cl(
        'gap-1 ring ring-white rounded-full flex h-6 items-center pl-1.5 pr-2 dark:ring-gray-900',
        isCurrentUserMessageLike
          ? 'bg-blue-500 text-white'
          : 'bg-gray-200 dark:bg-gray-700 dark:text-white'
      )}
      disabled={toggleMessageLikeLoading}
      emojiText={emojiText}
      key={emojiText}
      emojiCount={emojiCount}
      onClick={handleToggleMessageLike}
      tooltipUsers={tooltipUsers}
    />
  );
}

export default ItemMessagesListMessageLikesListItem;
