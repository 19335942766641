import { LightboxItem } from '../../../../helpers/LightboxWrapper';
import { LightboxWrapperItemDownloadType } from '../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import {
  ItemMessagesListMessageImageFileAttachmentAttachment,
  ItemMessagesListMessageImageSelectedLifestyleAttachment,
  ItemMessagesListMessageImageSelectedMaterialAttachment,
  ItemMessagesListMessageImageSelectedProductAttachment
} from '../../components/list/ItemMessagesListMessage/components/ItemMessagesListMessageImage';
import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../messagesTypes';

function mapMessageAttachmentImage(
  attachment:
    | ItemMessagesListMessageImageFileAttachmentAttachment
    | ItemMessagesListMessageImageSelectedProductAttachment
    | ItemMessagesListMessageImageSelectedLifestyleAttachment
    | ItemMessagesListMessageImageSelectedMaterialAttachment
): LightboxItem | undefined {
  switch (attachment.type) {
    case MessageAttachmentTypes.FILE_ATTACHMENT:
      return {
        uuid: attachment.uuid,
        image: attachment,
        downloadType: LightboxWrapperItemDownloadType.FILE_ATTACHMENTS
      };
    case MessageAttachmentTypes.SELECTED_PRODUCT:
      return {
        ...attachment.product,
        downloadType: LightboxWrapperItemDownloadType.PRODUCTS
      };
    case MessageAttachmentTypes.SELECTED_LIFESTYLE:
      return {
        ...attachment.lifestyle,
        downloadType: LightboxWrapperItemDownloadType.LIFESTYLES
      };
    case MessageAttachmentTypes.SELECTED_MATERIAL:
      return {
        ...attachment.material,
        downloadType: LightboxWrapperItemDownloadType.MATERIALS
      };
    case MessageFinAttachmentTypes.FILE_ATTACHMENT:
      return {
        uuid: attachment.uuid,
        image: attachment,
        downloadType: LightboxWrapperItemDownloadType.FILE_ATTACHMENTS
      };
    case MessageFinAttachmentTypes.SELECTED_PRODUCT:
      return {
        ...attachment.product,
        downloadType: LightboxWrapperItemDownloadType.PRODUCTS
      };
    case MessageFinAttachmentTypes.SELECTED_LIFESTYLE:
      return {
        ...attachment.lifestyle,
        downloadType: LightboxWrapperItemDownloadType.LIFESTYLES
      };
    case MessageFinAttachmentTypes.SELECTED_MATERIAL:
      return {
        ...attachment.material,
        downloadType: LightboxWrapperItemDownloadType.MATERIALS
      };
    default:
      return {
        uuid: attachment.uuid,
        image:
          attachment as ItemMessagesListMessageImageFileAttachmentAttachment,
        downloadType: LightboxWrapperItemDownloadType.FILE_ATTACHMENTS
      };
  }
}

export default mapMessageAttachmentImage;
